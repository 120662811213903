var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.dataObj.type === 'detail-data-revenue' ||
        _vm.dataObj.type === 'detail-data-expense'
    )?_c('div',{class:`text-sm d-flex ${
        _vm.dataObj.accountType === 'S' ? 'font-weight-bold' : ''
      }`},[_c('div',{class:`account-number-col-${_vm.reportDisplaySetting.pdfOrientation}`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.accountNumber))])]),_c('div',{class:`account-name-col-${_vm.reportDisplaySetting.pdfOrientation}`},[_c('span',[_vm._v(" "+_vm._s(_vm.addIndent(`${_vm.dataObj.accountName}`))+" ")])]),_c('div',{class:`account-balance-col-${_vm.reportDisplaySetting.pdfOrientation}  text-right`},[_c('span',[_vm._v(_vm._s(+_vm.dataObj.balance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.balance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.balance * -1)})`))])])]):_vm._e(),(
      _vm.dataObj.type === 'summary-data-revenue' ||
        _vm.dataObj.type === 'summary-data-expense' ||
        _vm.dataObj.type === 'summary-data-final'
    )?_c('div',{staticClass:"text-sm d-flex my-2"},[_c('div',{class:`account-number-col-${_vm.reportDisplaySetting.pdfOrientation} py-2`}),_c('div',{class:`account-name-col-${_vm.reportDisplaySetting.pdfOrientation} text-center py-2`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.title))])]),_c('div',{class:`account-balance-col-${
          _vm.reportDisplaySetting.pdfOrientation
        } summary-data-border-top ${
          _vm.dataObj.type === 'summary-data-final' ||
          _vm.dataObj.type === 'summary-data-revenue'
            ? 'summary-data-border-bottom'
            : ''
        } text-right py-2`},[_c('span',[_vm._v(_vm._s(+_vm.dataObj.balance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.balance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.balance * -1)})`))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }