<template>
  <div class="text-sm">
    <!-- datetime / company name / page number -->
    <div class="d-flex justify-content-between">
      <div class="w-25">
        <span>{{ getDate(dataProp.date) }}</span>
        <br />
        <span>{{ getTime(dataProp.date) }} น.</span>
      </div>
      <div class="w-50 text-center">
        <span>{{ dataProp.companyName }}</span>
      </div>
      <div class="w-25 text-right">
        <span>หน้า {{ pageProp }}</span>
      </div>
    </div>
    <!-- duration -->
    <div class="text-center my-3">
      <span> เพียง {{ dataProp.toDate }} </span>
    </div>
    <!-- report title -->
    <div class="text-center my-3">
      <span>
        {{ `งบกำไร(ขาดทุน)`.split('').join(' ') }}
      </span>
    </div>
    <!-- table header -->
    <div class="d-flex">
      <div
        :class="`table-header-width-empty-${reportDisplaySetting.pdfOrientation} text-center`"
      ></div>
      <div
        :class="`table-header-width-${reportDisplaySetting.pdfOrientation} table-header-border text-center`"
      >
        <span v-if="dataProp.cumulative">
          ยอดรวมทั้งปีของงวด {{ dataProp.accountMonth }}
        </span>
        <span v-else> ยอดรวมเฉพาะเพียงงวด {{ dataProp.accountMonth }} </span>
      </div>
    </div>
    <!-- table subheader -->
    <div class="d-flex">
      <div
        :class="`account-id-col-${reportDisplaySetting.pdfOrientation} table-sub-header-border`"
      >
        <span>รหัสบัญชี</span>
      </div>
      <div
        :class="`account-name-col-${reportDisplaySetting.pdfOrientation} table-sub-header-border`"
      >
        <span>ชื่อบัญชี</span>
      </div>
      <div
        :class="`account-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-sub-header-border`"
      >
        <span>ยอดรวม</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataProp: Object,
    pageProp: Number,
    reportDisplaySettingProp: Object
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      }
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
      }
    },
    getDate(value) {
      if (value) {
        const yymmdd = value.split('T')[0].split('-')
        return `${yymmdd[2]}/${yymmdd[1]}/${+yymmdd[0] + 543}`
      }
      return '-'
    },
    getTime(value) {
      if (value) {
        const hhmmss = value.split('T')[1].split('.')[0].split(':')
        return `${hhmmss[0]} : ${hhmmss[1]}`
      }
      return '-'
    }
  }
}
</script>

<style scoped>
.table-header-border {
  border-bottom: 1px solid black;
  padding: 5px 0px 10px 0px;
  margin: 0px 2px;
}
.table-sub-header-border {
  border-bottom: 1px solid black;
  padding: 5px 0px 10px 0px;
  margin: 5px 2px;
}
/* Portrait */
.table-header-width-empty-portrait {
  width: 75%;
}
.table-header-width-portrait {
  width: 25%;
}
.account-id-col-portrait {
  width: 25%;
}
.account-name-col-portrait {
  width: 50%;
}
.account-balance-col-portrait {
  width: 25%;
}
/* Landscape */
.table-header-width-empty-landscape {
  width: 75%;
}
.table-header-width-landscape {
  width: 25%;
}
.account-id-col-landscape {
  width: 25%;
}
.account-name-col-landscape {
  width: 50%;
}
.account-balance-col-landscape {
  width: 25%;
}
</style>
