<script>
import { mapState } from 'vuex'

import { datetimeMixins, toastMixins } from '@/mixins'
import service from '@/services/report/classify.js'

import ExportPdf from './ExportPdf.vue'
import ExportPdfByPdfMake from './ExportPdfByPdfMake.vue'

export default {
  mixins: [datetimeMixins, toastMixins],
  components: { ExportPdf, ExportPdfByPdfMake },
  props: {
    shouldUpdateReportHistoryProp: Boolean
  },
  data() {
    return {
      fields: [
        { key: 'no', label: 'ลำดับที่' },
        { key: 'accountMonth', label: 'งวด' },
        { key: 'remark', label: 'หมายเหตุ' },
        { key: 'cumulative', label: 'กำไรสะสม' },
        { key: 'bundle24months', label: 'พันยอด' },
        { key: 'displayUnavailableAccount', label: 'บ/ช ไม่เคลื่อนไหว' },
        { key: 'displaySummaryAccount', label: 'บ/ช สรุปยอด' },
        { key: 'createdAt', label: 'สร้างเมื่อ' },
        { key: 'downloadUseDelete', label: '' }
      ],
      originalReportHistory: [],
      reportHistory: [],
      startExportPdf: false,
      isDeleteLoading: false,
      profitAndLossStatementData: {},
      errMsg: null,
      defaultReportDisplaySettingProp: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    })
  },
  watch: {
    shouldUpdateReportHistoryProp() {
      if (this.shouldUpdateReportHistoryProp) {
        this.fetchReportHistories()
        this.$emit('updateReportHistorySuccessful')
      }
    }
  },
  created() {
    this.fetchReportHistories()
  },
  methods: {
    async fetchReportHistories() {
      try {
        const res = await service.getProfitAndLossStatementHistories()
        this.originalReportHistory = [...res.data.data]
        this.reportHistory = res.data.data.map((el, index) =>
          this.reformatFields(el, index + 1)
        )
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ดึงข้อมูลประวัติการออกรายงานงบกำไรขาดทุนไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    reformatFields(report, index) {
      const {
        id,
        accountMonth,
        remark,
        bundle24months,
        cumulative,
        displayUnavailableAccount,
        displaySummaryAccount,
        createdAt
      } = report

      return {
        no: index,
        historyId: id,
        accountMonth: accountMonth,
        remark: remark,
        bundle24months: bundle24months,
        cumulative: cumulative,
        displayUnavailableAccount: displayUnavailableAccount,
        displaySummaryAccount: displaySummaryAccount,
        createdAt: createdAt
      }
    },
    reportGeneratedHandler() {
      this.startExportPdf = false
    },
    downloadButtonClicked(historyId) {
      const el = this.originalReportHistory.find(
        (report) => report.id === historyId
      )
      this.profitAndLossStatementData = {
        accountMonth: el.accountMonth,
        remark: el.remark,
        bundle24months: el.bundle24months,
        cumulative: el.cumulative,
        displayUnavailableAccount: el.displayUnavailableAccount,
        displaySummaryAccount: el.displaySummaryAccount,
        saveReportSetting: false
      }
      this.startExportPdf = true
    },
    applyButtonClicked(historyId) {
      const element = this.originalReportHistory.find(
        (report) => report.id === historyId
      )
      const reformatElement = {}
      Object.keys(element).forEach((key) => {
        reformatElement[key] = element[key]
      })
      this.$emit('applyReportData', reformatElement)
    },
    async deleteButtonClicked(historyId) {
      this.isDeleteLoading = true
      try {
        await service.deleteProfitAndLossStatementHistoryById(historyId)
        await this.fetchReportHistories()
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ลบประวัติการออกรายงานงบกำไรขาดทุนไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isDeleteLoading = false
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12" class="bg-light p-4" style="border-radius: 10px">
      <div>
        <!-- topic -->
        <div>
          <span class="text-lg font-weight-bold">ประวัติการออกรายงาน</span>
        </div>

        <!-- table -->
        <div class="mt-3 overflow-auto">
          <b-table
            striped
            hover
            bordered
            small
            :fields="fields"
            :items="reportHistory"
            :head-variant="'dark'"
            :sticky-header="true"
            class="text-sm"
          >
            <!-- table header -->
            <template #head(no)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(accountMonth)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(remark)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(bundle24months)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(displayUnavailableAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(displaySummaryAccount)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(createdAt)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(downloadAndDelete)="data">
              <div class="header-column-style">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <!-- table body -->
            <template #cell(no)="data">
              {{ data.item.no }}
            </template>
            <template #cell(accountMonth)="data">
              {{ data.item.accountMonth || '-' }}
            </template>
            <template #cell(remark)="data">
              {{ data.item.remark || '-' }}
            </template>
            <template #cell(cumulative)="data">
              {{ data.item.cumulative ? 'ใช่' : '-' }}
            </template>
            <template #cell(bundle24months)="data">
              {{ data.item.bundle24months ? 'ใช่' : '-' }}
            </template>
            <template #cell(displayUnavailableAccount)="data">
              {{ data.item.displayUnavailableAccount ? 'ใช่' : '-' }}
            </template>
            <template #cell(displaySummaryAccount)="data">
              {{ data.item.displaySummaryAccount ? 'ใช่' : '-' || '-' }}
            </template>
            <template #cell(createdAt)="data">
              {{ mxConvertToDatetimeBuddhistFormat(data.item.createdAt) }}
            </template>
            <template #cell(downloadUseDelete)="data">
              <div class="d-flex justify-content-center">
                <div class="mr-2">
                  <b-button
                    v-if="user && user.permission.reportProfitAndLoss.canPrint"
                    variant="success"
                    size="sm"
                    @click="downloadButtonClicked(data.item.historyId)"
                    :disabled="startExportPdf || isDeleteLoading"
                  >
                    <b-icon icon="download" scale="0.8"></b-icon>
                  </b-button>
                </div>
                <div class="mr-2">
                  <b-button
                    v-if="user && user.permission.reportProfitAndLoss.canPrint"
                    variant="primary"
                    size="sm"
                    :disabled="startExportPdf || isDeleteLoading"
                  >
                    <b-icon
                      icon="box-arrow-up"
                      scale="0.8"
                      @click="applyButtonClicked(data.item.historyId)"
                    ></b-icon>
                  </b-button>
                </div>
                <div>
                  <b-button
                    v-if="user && user.permission.reportProfitAndLoss.canDelete"
                    variant="danger"
                    size="sm"
                    @click="deleteButtonClicked(data.item.historyId)"
                    :disabled="startExportPdf || isDeleteLoading"
                  >
                    <b-icon icon="trash" scale="0.8"></b-icon>
                  </b-button>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-col>

    <!-- export pdf -->
    <!-- <b-col class="d-none">
      <b-col>
        <export-pdf
          :click="startExportPdf"
          :profitAndLossStatementDataProp="profitAndLossStatementData"
          @reportGenerated="reportGeneratedHandler"
        ></export-pdf>
      </b-col>
    </b-col> -->

    <!-- export pdf by pdfmake.js -->
    <b-row class="d-none">
      <b-col>
        <export-pdf-by-pdf-make
          :click="startExportPdf"
          :reportDisplaySettingProp="defaultReportDisplaySettingProp"
          :profitAndLossStatementDataProp="profitAndLossStatementData"
          @reportGenerated="reportGeneratedHandler"
        ></export-pdf-by-pdf-make>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-row>
</template>

<style scoped>
.header-column-style {
  text-align: center;
}
</style>
