<script>
import lodash from 'lodash'

export default {
  props: {
    reportDisplaySettingProp: Object,
    modalShowProp: Boolean
  },
  data() {
    return {
      pdfOrientation: null,
      pdfOrientationOptions: ['portrait', 'landscape'],
      summaryAccountIndent: null,
      summaryAccountIndexOptions: lodash
        .times(5)
        .map(n => ({ value: n + 1, text: n + 1 }))
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.initialize()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      const {
        pdfOrientation,
        summaryAccountIndent
      } = this.reportDisplaySettingProp
      this.pdfOrientation = pdfOrientation
      this.summaryAccountIndent = summaryAccountIndent
    },
    confirmButtonClicked() {
      this.$emit('modalClosed', {
        pdfOrientation: this.pdfOrientation,
        summaryAccountIndent: this.summaryAccountIndent
      })
    },
    cancelButtonClicked() {
      this.initialize()
      this.$emit('modalClosed')
    }
  }
}
</script>

<template>
  <b-modal
    v-model="modalShowProp"
    :title="'ตั้งค่าการแสดงผลของรายงาน'"
    :header-bg-variant="'primary'"
    :header-text-variant="'light'"
    :hide-footer="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
  >
    <b-row>
      <b-col cols="12" class="mb-5">
        <!-- แนวกระดาษ -->
        <div class="d-flex">
          <div class="mr-3">
            <label>
              <b>แนวกระดาษ</b>
            </label>
          </div>
          <div class="d-flex">
            <b-form-radio
              v-model="pdfOrientation"
              :value="pdfOrientationOptions[0]"
              class="mr-3"
            >
              แนวตั้ง
            </b-form-radio>
            <b-form-radio
              v-model="pdfOrientation"
              :value="pdfOrientationOptions[1]"
            >
              แนวนอน
            </b-form-radio>
          </div>
        </div>

        <!-- ย่อหน้า บัญชีสรุปยอด -->
        <div class="d-flex">
          <div class="d-flex align-items-center mr-3">
            <span>
              <b>ย่อหน้าบัญชีสรุปยอดต่อ 1 ระดับ</b>
            </span>
          </div>
          <div class="d-flex align-items-center">
            <b-form-select
              v-model="summaryAccountIndent"
              :options="summaryAccountIndexOptions"
              class="mr-3"
            ></b-form-select>
            <div>
              <span>ช่อง</span>
            </div>
          </div>
        </div>
      </b-col>

      <!-- hover line -->
      <b-col cols="12">
        <hr />
      </b-col>

      <!-- buttons -->
      <b-col cols="12">
        <div class="d-flex justify-content-end">
          <div class="mr-3">
            <b-button variant="primary" @click="confirmButtonClicked()">
              ตกลง
            </b-button>
          </div>
          <div>
            <b-button variant="outline-primary" @click="cancelButtonClicked()">
              ยกเลิก
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style></style>
