<script>
import { isEmpty, times } from 'lodash'
import moment from 'moment'
import pdfMake from 'pdfmake'

import pdfFonts from '@/assets/fonts/custom-fonts.js'
import reportClassifyService from '@/services/report/classify.js'
import { toastMixins, datetimeMixins, numberMixins } from '@/mixins'
import * as datetimeUtils from '@/utils/datetime'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

export default {
  mixins: [toastMixins, datetimeMixins, numberMixins],
  props: {
    click: Boolean,
    reportDisplaySettingProp: Object,
    profitAndLossStatementDataProp: Object
  },
  data() {
    return {
      reportData: {},
      docDefinition: {},
      errMsg: null
    }
  },
  watch: {
    click() {
      if (this.click) {
        this.generateReport()
      }
    }
  },
  methods: {
    getThaiDatePhrase(time) {
      return datetimeUtils.getThaiDatePhrase(time)
    },
    async generateReport() {
      await this.fetchProfitAndLossStatementData()
      this.generateDocDefinition()
    },
    async fetchProfitAndLossStatementData() {
      if (!isEmpty(this.profitAndLossStatementDataProp)) {
        try {
          const res =
            await reportClassifyService.postGenerateProfitAndLossStatement(
              this.profitAndLossStatementDataProp
            )
          this.reportData = res.data.data
        } catch (err) {
          const errMessage =
            err.response?.data.thMessage ||
            'ดึงข้อมูลสำหรับออกรายงานงบกำไรขาดทุนไม่สำเร็จ'
          this.errMsg = errMessage
        }
      }
    },
    generateDocDefinition() {
      if (
        this.click &&
        !isEmpty(this.reportDisplaySettingProp) &&
        !isEmpty(this.profitAndLossStatementDataProp)
      ) {
        // setup pdf config
        this.setDocDefinitionConfig()

        // declare variables
        let count = 0
        let limit =
          this.reportDisplaySettingProp.pdfOrientation === 'portrait' ? 23 : 13
        let pageNumber = 0
        let totalPageNumber = this.findTotalPageNumber(this.reportData, limit)

        // revenue detail
        this.reportData.revenueData.forEach((data) => {
          if (count % limit === 0) {
            pageNumber++
            this.generateDocDefinitionContentHeader(pageNumber, totalPageNumber)
          }
          count++
          this.generateDocDefinitionContentBodyDetail({
            accountLevel: data.accountLevel,
            accountNumber: data.accountNumber,
            accountName: data.accountName,
            balance: data.balance,
            accountType: data.accountType,
            pageBreakAfter: count % limit === 0,
            pdfOrientation: this.reportDisplaySettingProp.pdfOrientation
          })
        })

        // revenue summary
        if (count % limit === 0) {
          pageNumber++
          this.generateDocDefinitionContentHeader(pageNumber, totalPageNumber)
        }
        count++
        this.generateDocDefinitionContentBodySummary({
          label: 'รวมรายได้',
          balance: this.reportData.totalRevenueBalance,
          borderTop: true,
          borderBottom: true,
          marginTop: true,
          marginBottom: true,
          pageBreakAfter: count % limit === 0
        })

        // expense detail
        this.reportData.expenseData.forEach((data) => {
          if (count % limit === 0) {
            pageNumber++
            this.generateDocDefinitionContentHeader(pageNumber, totalPageNumber)
          }
          count++
          this.generateDocDefinitionContentBodyDetail({
            accountLevel: data.accountLevel,
            accountNumber: data.accountNumber,
            accountName: data.accountName,
            balance: data.balance,
            accountType: data.accountType,
            pageBreakAfter: count % limit === 0
          })
        })

        // expense summary
        if (count % limit === 0) {
          pageNumber++
          this.generateDocDefinitionContentHeader(pageNumber, totalPageNumber)
        }
        count++
        this.generateDocDefinitionContentBodySummary({
          label: 'รวมค่าใช้จ่าย',
          balance: this.reportData.totalExpenseBalance,
          borderTop: true,
          borderBottom: true,
          marginTop: true,
          marginBottom: false,
          pageBreakAfter: count % limit === 0
        })

        // profit and loss summary
        if (count % limit === 0) {
          pageNumber++
          this.generateDocDefinitionContentHeader(pageNumber, totalPageNumber)
        }
        count++
        this.generateDocDefinitionContentBodySummary({
          label: 'กำไร(ขาดทุน)สุทธิ',
          balance: this.reportData.totalProfitAndLossBalance,
          borderTop: false,
          borderBottom: true,
          marginTop: false,
          marginBottom: false,
          pageBreakAfter: count % limit === 0
        })

        // generate pdf
        pdfMake.createPdf(this.docDefinition).open()
      }
      this.$emit('reportGenerated')
    },
    setDocDefinitionConfig() {
      this.docDefinition = {
        pageSize: 'A4',
        pageOrientation: this.reportDisplaySettingProp.pdfOrientation,
        pageMargins: [25, 25],
        info: {
          title: 'งบกำไรขาดทุน',
          author: 'happysoft acccounting co., ltd'
        },
        content: [],
        styles: {
          alignmentLeft: {
            alignment: 'left'
          },
          alignmentCenter: {
            alignment: 'center'
          },
          alignmentRight: {
            alignment: 'right'
          }
        },
        defaultStyle: {
          font: 'THSarabunNew',
          fontSize: 15
        }
      }
    },
    generateDocDefinitionContentHeader(pageNumber, totalPageNumber) {
      const today = this.mxConvertToDatetimeBuddhistFormat(moment().toDate())
      const date = today.split(' ')[0]
      const time = today.split(' ')[1].replace('(', '').replace(')', '') + ' น.'

      this.docDefinition.content.push(
        {
          columns: [
            {
              text: this.reportData.companyName,
              style: ['alignmentCenter'],
              bold: true,
              fontSize: 18,
              margin: [0, 0, 0, 5]
            }
          ]
        },
        {
          columns: [
            {
              text: `${date}\n${time}`,
              style: ['alignmentLeft'],
              width: '10%'
            },
            {
              text: `${`งบกำไร(ขาดทุน)`
                .split('')
                .join(' ')}\nเพียง ${this.getThaiDatePhrase(
                this.reportData.toDate
              )}`,
              style: ['alignmentCenter'],
              margin: [0, 5, 0, 0]
            },
            {
              text: `หน้า ${pageNumber}${
                totalPageNumber === 1 ? '' : '/' + totalPageNumber
              }`,
              style: ['alignmentRight'],
              width: '10%',
              margin: [0, 5, 0, 0]
            }
          ]
        },
        {
          table: {
            widths: [100, 1, '*', 1, 150],
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, false, false, false],
                  text: ''
                },
                {
                  border: [false, false, false, true],
                  text: `ยอดรวมทั้งปีของงวด ${this.reportData.accountMonth}`,
                  alignment: 'center',
                  margin: [0, 3]
                }
              ]
            ]
          }
        },
        {
          table: {
            widths: [100, 1, '*', 1, 150],
            body: [
              [
                {
                  border: [false, false, false, true],
                  margin: [0, 3],
                  text: 'รหัสบัญชี',
                  alignment: 'left'
                },
                {
                  border: [false, false, false, false],
                  margin: [0, 3],
                  text: ''
                },
                {
                  border: [false, false, false, true],
                  margin: [0, 3],
                  text: 'ชื่อบัญชี',
                  alignment: 'left'
                },
                {
                  border: [false, false, false, false],
                  margin: [0, 3],
                  text: ''
                },
                {
                  border: [false, false, false, true],
                  margin: [0, 3],
                  text: 'ยอดรวม',
                  alignment: 'right'
                }
              ]
            ]
          }
        }
      )
    },
    generateDocDefinitionContentBodyDetail(payload) {
      const {
        accountLevel,
        accountName,
        accountNumber,
        balance,
        accountType,
        pageBreakAfter
      } = payload

      const el = {
        table: {
          widths: [100, 1, '*', 1, 150],
          body: [
            [
              {
                border: [false, false, false, false],
                text: `${accountNumber}`,
                alignment: 'left',
                bold:
                  this.profitAndLossStatementDataProp &&
                  this.profitAndLossStatementDataProp.displaySummaryAccount &&
                  accountType === 'S'
              },
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: `${this.getAccountName(accountLevel, accountName)}`,
                alignment: 'left',
                preserveLeadingSpaces: true,
                bold:
                  this.profitAndLossStatementDataProp &&
                  this.profitAndLossStatementDataProp.displaySummaryAccount &&
                  accountType === 'S'
              },
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: this.mxNumeralThousandSeperate(balance),
                alignment: 'right',
                bold:
                  this.profitAndLossStatementDataProp &&
                  this.profitAndLossStatementDataProp.displaySummaryAccount &&
                  accountType === 'S'
              }
            ]
          ]
        }
      }

      if (pageBreakAfter) {
        el.pageBreak = 'after'
      }

      this.docDefinition.content.push(el)
    },
    generateDocDefinitionContentBodySummary(payload) {
      const {
        label,
        balance,
        borderTop,
        borderBottom,
        marginTop,
        marginBottom,
        pageBreakAfter
      } = payload

      const topSpace = { text: '', margin: [0, 0, 0, 0] }
      const element = {
        table: {
          widths: [100, 1, '*', 1, 150],
          margin: [0, 5],
          body: [
            [
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: `${label}`,
                alignment: 'center',
                margin: [0, 5]
              },
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, borderTop, false, borderBottom],
                text: this.mxNumeralThousandSeperate(balance),
                alignment: 'right',
                margin: [0, 5]
              }
            ]
          ]
        }
      }
      const bottomSpace = { text: '', margin: [0, 0, 0, 0] }

      if (marginTop) {
        topSpace.margin[1] = 5
      }
      if (marginBottom) {
        bottomSpace.margin[3] = 5
      }

      if (pageBreakAfter) {
        bottomSpace.pageBreak = 'after'
      }

      this.docDefinition.content.push(topSpace, element, bottomSpace)
    },
    getAccountName(level, name) {
      if (
        this.profitAndLossStatementDataProp &&
        this.profitAndLossStatementDataProp.displaySummaryAccount
      ) {
        const indent =
          (level - 1) * this.reportDisplaySettingProp.summaryAccountIndent
        const newName =
          times(indent)
            .map(() => '\xa0')
            .join('') + name
        return newName
      }
      return name
    },
    findTotalPageNumber(dataObj, limit) {
      const expenseDataLength = dataObj.expenseData.length
      const revenueDataLength = dataObj.revenueData.length
      const totalBalancesLength = 3

      if (expenseDataLength === 0 && revenueDataLength === 0) return 1

      return Math.ceil(
        (expenseDataLength + revenueDataLength + totalBalancesLength) / limit
      )
    }
  }
}
</script>

<template>
  <div>
    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
