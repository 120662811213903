<template>
  <div class="d-flex text-sm my-2">
    <div
      :class="
        `account-name-col-${reportDisplaySetting.pdfOrientation} text-right py-2 ml-1`
      "
    >
      <span>ยอดคงเหลือทั้งสิ้น</span>
    </div>
    <div
      :class="
        `account-type-col-${reportDisplaySetting.pdfOrientation} text-center py-2 ml-1`
      "
    >
      <span>:</span>
    </div>
    <div
      :class="
        `previous-balance-col-${reportDisplaySetting.pdfOrientation} table-footer-border text-right py-2 ml-1`
      "
    >
      <span>{{
        mxNumeralThousandSeperate(dataProp.finalBalance.previousBalance.debit)
      }}</span>
    </div>
    <div
      :class="
        `previous-balance-col-${reportDisplaySetting.pdfOrientation} table-footer-border text-right py-2 ml-1`
      "
    >
      <span>{{
        mxNumeralThousandSeperate(dataProp.finalBalance.previousBalance.credit)
      }}</span>
    </div>
    <div
      :class="
        `current-balance-col-${reportDisplaySetting.pdfOrientation} table-footer-border text-right py-2 ml-1`
      "
    >
      <span>{{
        mxNumeralThousandSeperate(dataProp.finalBalance.currentBalance.debit)
      }}</span>
    </div>
    <div
      :class="
        `current-balance-col-${reportDisplaySetting.pdfOrientation} table-footer-border text-right py-2 ml-1`
      "
    >
      <span>{{
        mxNumeralThousandSeperate(dataProp.finalBalance.currentBalance.credit)
      }}</span>
    </div>
    <div
      :class="
        `total-balance-col-${reportDisplaySetting.pdfOrientation} table-footer-border text-right py-2 ml-1`
      "
    >
      <span>{{
        mxNumeralThousandSeperate(dataProp.finalBalance.totalBalance.debit)
      }}</span>
    </div>
    <div
      :class="
        `total-balance-col-${reportDisplaySetting.pdfOrientation} table-footer-border text-right py-2 ml-1`
      "
    >
      <span>{{
        mxNumeralThousandSeperate(dataProp.finalBalance.totalBalance.credit)
      }}</span>
    </div>
  </div>
</template>

<script>
import { numberMixins } from '@/mixins'

export default {
  mixins: [numberMixins],
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      }
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
      }
    }
  }
}
</script>

<style scoped>
.table-footer-border {
  border-top: 1px solid black;
}
/* Portrait */
.account-name-col-portrait {
  width: 21%;
}
.account-type-col-portrait {
  width: 4%;
}
.previous-balance-col-portrait {
  width: 12.5%;
}
.current-balance-col-portrait {
  width: 12.5%;
}
.total-balance-col-portrait {
  width: 12.5%;
}
/* Landscape */
.account-name-col-landscape {
  width: 41%;
}
.account-type-col-landscape {
  width: 5%;
}
.previous-balance-col-landscape {
  width: 18%;
}
.current-balance-col-landscape {
  width: 18%;
}
.total-balance-col-landscape {
  width: 18%;
}
</style>
