<template>
  <div>
    <div
      v-if="
        dataObj.type === 'detail-data-revenue' ||
          dataObj.type === 'detail-data-expense'
      "
      :class="
        `text-sm d-flex ${
          dataObj.accountType === 'S' ? 'font-weight-bold' : ''
        }`
      "
    >
      <div :class="`account-number-col-${reportDisplaySetting.pdfOrientation}`">
        <span>{{ dataObj.accountNumber }}</span>
      </div>
      <div :class="`account-name-col-${reportDisplaySetting.pdfOrientation}`">
        <span>
          {{ addIndent(`${dataObj.accountName}`) }}
        </span>
      </div>
      <div
        :class="
          `account-balance-col-${reportDisplaySetting.pdfOrientation}  text-right`
        "
      >
        <span>{{
          +dataObj.balance >= 0
            ? mxNumeralThousandSeperate(dataObj.balance)
            : `(${mxNumeralThousandSeperate(dataObj.balance * -1)})`
        }}</span>
      </div>
    </div>
    <div
      v-if="
        dataObj.type === 'summary-data-revenue' ||
          dataObj.type === 'summary-data-expense' ||
          dataObj.type === 'summary-data-final'
      "
      class="text-sm d-flex my-2"
    >
      <div
        :class="
          `account-number-col-${reportDisplaySetting.pdfOrientation} py-2`
        "
      ></div>
      <div
        :class="
          `account-name-col-${reportDisplaySetting.pdfOrientation} text-center py-2`
        "
      >
        <span>{{ dataObj.title }}</span>
      </div>
      <div
        :class="
          `account-balance-col-${
            reportDisplaySetting.pdfOrientation
          } summary-data-border-top ${
            dataObj.type === 'summary-data-final' ||
            dataObj.type === 'summary-data-revenue'
              ? 'summary-data-border-bottom'
              : ''
          } text-right py-2`
        "
      >
        <span>{{
          +dataObj.balance >= 0
            ? mxNumeralThousandSeperate(dataObj.balance)
            : `(${mxNumeralThousandSeperate(dataObj.balance * -1)})`
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'

import { numberMixins } from '@/mixins'

export default {
  mixins: [numberMixins],
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object,
    displaySummaryAccountProp: Boolean
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      dataObj: {
        accountName: null,
        accountNumber: null,
        accountType: null,
        balance: null,
        type: null
      }
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    dataProp() {
      this.assignDataFromProp('dataProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('dataProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'dataProp':
          this.dataObj = { ...this.dataProp }
          break
      }
    },
    addIndent(word) {
      if (this.displaySummaryAccountProp === false) {
        return word
      } else {
        const { accountLevel } = this.dataProp
        const indent =
          (accountLevel - 1) * this.reportDisplaySetting.summaryAccountIndent
        const newWord =
          lodash
            .times(indent)
            .map(() => '\xa0')
            .join('') + word
        return newWord
      }
    }
  }
}
</script>

<style scoped>
/* Portrait */
.account-number-col-portrait {
  width: 25%;
}
.account-name-col-portrait {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
}
.account-balance-col-portrait {
  width: 25%;
}
/* Landscape */
.account-number-col-landscape {
  width: 25%;
}
.account-name-col-landscape {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
}
.account-balance-col-landscape {
  width: 25%;
}
/* Summary data */
.summary-data-border-top {
  border-top: 1px solid black;
}
.summary-data-border-bottom {
  border-bottom: 1px solid black;
}
</style>
