<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('logout')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="box-arrow-right"
          ></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttonClass:
        'shadow m-1 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2'
    }
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'logout':
          this.$router.push({ name: 'Home' })
          break
      }
    }
  }
}
</script>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
