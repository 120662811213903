<template>
  <div>
    <div v-for="(data, index) in reportData.dataForExportPdf" :key="index">
      <PdfContentHeader
        v-if="index % pdfOrientationLimit === 0"
        :dataProp="reportData"
        :pageProp="index / pdfOrientationLimit + 1"
        :reportDisplaySettingProp="reportDisplaySetting"
      ></PdfContentHeader>
      <PdfContentBody
        :dataProp="data"
        :reportDisplaySettingProp="reportDisplaySetting"
        :displaySummaryAccountProp="displaySummaryAccount"
      ></PdfContentBody>
      <div
        v-if="index % pdfOrientationLimit === pdfOrientationLimit - 1"
        class="html2pdf__page-break"
      ></div>
    </div>
  </div>
</template>

<script>
import PdfContentHeader from './PdfContentHeader.vue'
import PdfContentBody from './PdfContentBody.vue'
import PdfContentFooter from './PdfContentFooter.vue'

export default {
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object,
    displaySummaryAccountProp: Boolean
  },
  components: {
    PdfContentHeader,
    PdfContentBody,
    PdfContentFooter
  },
  data() {
    return {
      limit: 37,
      reportData: {
        date: null,
        companyName: null,
        toDate: null,
        accountMonth: null,
        cumulative: false,
        totalRevenueBalance: null,
        revenueData: [],
        totalExpenseBalance: null,
        expenseData: [],
        totalProfitAndLossBalance: null,
        dataForExportPdf: []
      },
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      displaySummaryAccount: false
    }
  },
  computed: {
    pdfOrientationLimit() {
      if (this.reportDisplaySetting.pdfOrientation === 'portrait') {
        return this.limit
      } else {
        return this.limit - 12
      }
    }
  },
  watch: {
    dataProp() {
      this.reportData = { ...this.dataProp }
      const dataForExportPdf = [
        ...this.dataProp.revenueData.map(data => ({
          type: 'detail-data-revenue',
          ...data
        })),
        {
          type: 'summary-data-revenue',
          title: 'รวมรายได้',
          balance: this.dataProp.totalRevenueBalance
        },
        ...this.dataProp.expenseData.map(data => ({
          type: 'detail-data-expense',
          ...data
        })),
        {
          type: 'summary-data-expense',
          title: 'รวมค่าใช้จ่าย',
          balance: this.dataProp.totalExpenseBalance
        },
        {
          type: 'summary-data-final',
          title: 'กำไร(ขาดทุน)สุทธิ',
          balance: this.dataProp.totalProfitAndLossBalance
        }
      ]
      this.reportData.dataForExportPdf = [...dataForExportPdf]
    },
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    displaySummaryAccountProp() {
      this.assignDataFromProp('displaySummaryAccountProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('displaySummaryAccountProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'displaySummaryAccountProp':
          this.displaySummaryAccount = this.displaySummaryAccountProp
          break
      }
    }
  }
}
</script>

<style></style>
